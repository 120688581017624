.app {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.field {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.invisible {
  opacity: 0;
}
.nav {
  position: fixed;
  margin-top: -22px;
  font-family: 'Major Mono Display', monospace;
  color: aliceblue;
  z-index: 999;
  width: 100vw;
  font-weight: bold;
  font-size: 2vh;
  letter-spacing: 5px;
  text-shadow: 1px 1px 2px rgb(10, 40, 174), 0 0 1em rgb(182, 48, 153), 0 0 0.2em;
  padding: 2%;
  padding-left: 4%;
  background-image: linear-gradient(to bottom, rgb(36, 120, 118), rgb(125, 90, 146));
  border-bottom: 7px double rgb(102, 68, 120);
}

.image {
  flex-wrap: wrap;
  margin: 3vh 3vw;
  width: 15vw;
  height: 15vw;
  box-shadow: 3px 3px 3px rgb(169, 169, 169);
  border-radius: 3px;
  border: 0.1px solid rgb(155, 155, 155);
}

.image:hover {
  filter: brightness(1.25);
  size: 110%;
  transform: scale(110%);
}

.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12vh;
  width: 12vw;
  z-index: 999;
  border-radius: 3px;
  animation: fadeIn 1s; 

}

@keyframes fadeIn {
  0% {opacity: 0; }
  100% { opacity: 1; }
}


.modalImg {
  height: 35vw;
  width: 35vw;
}

ul {
  list-style: none;
  background-color: rgb(239, 245, 255, 0.95);
  margin: 0;
  height: 35vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: space-around;
}

li, a {

  font-weight: 500;
  font-size: 2vw;
  padding: 0.5%;
  width: 35vw;
  flex-shrink: 5;
}

/* a {
  margin-left: -3px;
} */

